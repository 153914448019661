import { useMutation, useQueryClient } from "@tanstack/react-query"

import { CONTRACT_ANNEX_QUERY_KEY, CONTRACT_QUERY_KEY } from "@/constants/query"
import { closeContract, createContract, deleteContracts, updateContract } from "@/http/contract"

import { useMessage } from "@/hook/useMessage"

export const useContractMutation = () => {
    const client = useQueryClient()
    const { Success, Error, Process } = useMessage(CONTRACT_ANNEX_QUERY_KEY)

    const createMutation = useMutation({
        mutationFn: createContract,
        onSuccess: () => onSuccess(),
        onMutate: () => onMutate(),
        onError: (e) => onError(e)
    })

    const updateMutation = useMutation({
        mutationFn: updateContract,
        onSuccess: () => onSuccess(),
        onMutate: () => onMutate(),
        onError: (e) => onError(e)
    })

    const closeMutation = useMutation({
        mutationFn: closeContract,
        onSuccess: () => onSuccess(),
        onMutate: () => onMutate(),
        onError: (e) => onError(e)
    })

    const deleteMutation = useMutation({
        mutationFn: deleteContracts,
        onSuccess: () => onSuccess(),
        onMutate: () => onMutate(),
        onError: (e) => onError(e)
    })

    const onSuccess = () => {
        client.invalidateQueries([CONTRACT_QUERY_KEY])
        Success()
    }

    const onMutate = () => {
        Process()
    }

    const onError = (e: any) => {
        Error(e)
    }

    return {
        createMutation,
        updateMutation,
        closeMutation,
        deleteMutation
    }
}
