import { Tag } from "antd"

import { ITax } from "@/types/ITax"
import { ITableColumn } from "@/types/ui"

import { formatDate } from "@/utils/formatDate"

const EMPTY_DATA = "Нет"

export const InnerTaxesColumns: ITableColumn<ITax>[] = [
    {
        title: "Дата создания",
        key: "createdOn",
        dataIndex: ["createdOn"],
        align: "center",
        sorter: false,
        width: 130,
        isVisible: true,
        render: (_, { createdOn }) => <>{createdOn && new Date(createdOn).toLocaleString("ru-RU")}</>,
        order: 1
    },
    {
        title: "Ставка НДС",
        key: "taxRate",
        dataIndex: ["taxRate"],
        align: "center",
        sorter: false,
        width: 100,
        isVisible: true,
        render: (_, { taxRate }) => <>{taxRate} %</>,
        order: 2
    },
    {
        title: "Дата начала действия",
        key: "startDate",
        dataIndex: ["startDate"],
        align: "center",
        sorter: false,
        width: 130,
        isVisible: true,
        render: (_, { startDate }) => <Tag>{startDate && <span>{formatDate(startDate)}</span>}</Tag>,
        order: 1
    },
    {
        title: "Автор",
        key: "author",
        dataIndex: ["author"],
        align: "center",
        width: 190,
        sorter: false,
        sorterField: "authorId",
        render: (_, { author }) => <span key={author.id}>{author.fullName ?? EMPTY_DATA}</span>,
        isVisible: true,
        order: 4
      },
      {
        title: "Дата изменения",
        key: "lastModifiedOn",
        dataIndex: ["lastModifiedOn"],
        render: (_, { lastModifiedOn }) => <>{lastModifiedOn && new Date(lastModifiedOn).toLocaleString("ru-RU")}</>,
        align: "center",
        sorter: false,
        width: 130,
        isVisible: true,
        order: 5
      },
      {
        title: "Автор изменения",
        key: "lastModifiedBy",
        dataIndex: ["lastModifiedBy"],
        align: "center",
        width: 190,
        sorter: false,
        sorterField: "lastModifiedById",
        render: (_, { lastModifiedBy }) => <span key={lastModifiedBy.id}>{lastModifiedBy.fullName ?? EMPTY_DATA}</span>,
        isVisible: true,
        order: 6
      }
]

export const INNER_TAXES_KEY = "INNER_TAXES_KEY"