import { IAuthor } from "./IAuthor"

export interface ITax {
    id: number
    startDate: string
    taxRate: number
    authorizedOrganizationId: number
    author: IAuthor
    createdOn: string
    lastModifiedBy: IAuthor
    lastModifiedOn: string
}

export interface ITaxCreate {
    startDate: Date
    taxRate: number
    authorizedOrganizationId: number
}
  
export interface ITaxUpdate extends ITaxCreate {
    id: number
}

export const VALUE_ADDED_TAXES_KEY = "VALUE_ADDED_TAXES_KEY"