import { FC, Key, useRef, useState } from "react"

import { Spin } from "antd"

import { Table } from "@/components/Table"
import { Resource } from "@/constants/permission"

import { InnerTaxesColumns, INNER_TAXES_KEY } from "./ValueAddedTaxesColumns"
import { TaxAddFormProps, ValueAddedTaxForm } from "../ValueAddedTaxForm"

import { useValueAddedTaxMutation } from "@/hook/AuthorizedOrganizations/useValueAddedTaxMutation"
import { useTaxesByOrganizationQuery } from "@/hook/AuthorizedOrganizations/useValueAddedTaxQuery"
import { useTable } from "@/hook/useTable"

import { ITax } from "@/types/ITax"

import { getInitialColumns } from "@/utils/getInitialColumns"


interface TaxesTableProps {
    authorizedOrganizationId: number
}

export const ValueAddedTaxesTable: FC<TaxesTableProps> = (props) => {
    const initialColumns = getInitialColumns(InnerTaxesColumns, INNER_TAXES_KEY)

    const { columns, pagination, order } = useTable<ITax>({
        initialColumns: initialColumns
    })

    const { data, isLoading } = useTaxesByOrganizationQuery(
        props.authorizedOrganizationId,
        {
            Page: pagination.page,
            PageSize: pagination.pageSize,
            OrderBy: order.orderBy
        })

    const [pageSize, setPageSize] = useState(10)

    const onChangePageSize = (size: number) => {
        setPageSize(size)
    }

    const taxComponent = useRef<TaxAddFormProps>(null)

    const onAddHandler = () => {
        taxComponent.current?.open(props.authorizedOrganizationId)
    }

    const onEditHandler = (id: number) => {
        taxComponent.current?.open(props.authorizedOrganizationId, id)
    }

    const { deleteMutation } = useValueAddedTaxMutation()

    const onRemoveHandler = async (ids: Key[]) => {
        await deleteMutation.mutateAsync(ids.map((id) => Number(id)))
    }

    if (!data) {
        return <Spin/>
    }

    return (
        <>
            <ValueAddedTaxForm ref={taxComponent} />
            <Table
                hasSelection={true}
                columns={columns.data}
                dataSource={data?.response.data}
                isLoading={isLoading}
                rowKey="id"
                permission={{
                    resource: Resource.AuthorizedOrganization
                }}
                pagination={{
                    pageSize: pageSize,
                    onPageSizeChange: onChangePageSize
                }}
                onAdd={onAddHandler}
                onEdit={onEditHandler}
                onRemove={onRemoveHandler}
                onSorterChange={order.setOrderBy}
            />
        </>
    )
}