import { IContract } from "./IContract"
import { IEpsmApplication } from "./IEpsmApplication"

import { IAuthor } from "@/types/IAuthor"
import { IAuthorizedOrganization } from "@/types/IAuthorizedOrganization"
import { IClient } from "@/types/IClient"

export interface IContractAnnex {
    id: string
    contract?: IContract
    organization?: IAuthorizedOrganization
    number: string
    name: string
    date: string
    passportCount: number
    registeredCount: number
    readyCount: number
    amount: number
    client: IClient
    author: IAuthor
    createdOn: string
    lastModifiedBy: IAuthor
    lastModifiedOn: string
    deletedBy: IAuthor
    deletedOn: string
    jobGenitive?: string
    requisites?: string
    fullNameGenitive?: string
    jobNominative?: string
    fullNameNominative?: string
    isSigned: boolean
    isPaid: boolean
    isClosed: boolean
    closedDate: string
    preparationPrice: number
    complianceDocumentsList: string
    registrationPrice: number
    formalizationPrice: number
    correctionPrice: number
    cancellationPrice: number
    totalPrice: number
    taxRate: number
    additionalConditions: string
    isDeleted: boolean
    applications: IEpsmApplication[]
}

export interface IContractAnnexCreate {
    contractId: string
    name: string
    date: Date
    passportCount: number
    amount: number
    preparationPrice: number
    complianceDocumentsList: string
    registrationPrice: number
    formalizationPrice: number
    correctionPrice: number
    cancellationPrice: number
    totalPrice: number
    additionalConditions: string
}

export interface IContractAnnexUpdate extends IContractAnnexCreate {
    id: string
}

export interface IContractAnnexClose {
    id: string
    isSigned: boolean
    isPaid: boolean
    isClosed: boolean
    closedDate: Date
}

export interface IContractAnnexDelete {
    ids: string[]
}

export const CONTRACT_ANNEXES_KEY = "CONTRACT_ANNEXES_KEY"