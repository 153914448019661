import { useQuery } from "@tanstack/react-query"

import { getValueAddedTaxById, getValueAddedTaxes, getValueAddedTaxesByOrganization } from "@/http/valueAddedTax"

import { IRequestParams } from "@/types/api"
import { VALUE_ADDED_TAXES_KEY } from "@/types/ITax"

export const useValueAddedTaxesQuery = () => {
  return useQuery({
    queryFn: () => getValueAddedTaxes(),
    queryKey: [VALUE_ADDED_TAXES_KEY],
    retry: false
  })
}

export const useTaxesByOrganizationQuery = (id: number, params?: IRequestParams) => {
  return useQuery({
    queryFn: () => getValueAddedTaxesByOrganization(id, params || {}),
    queryKey: [VALUE_ADDED_TAXES_KEY, params],
    retry: false
  })
}

export const useValueAddedTaxByIdQuery = (id: number, options?: { refetchOnWindowFocus: boolean }) => {
    return useQuery({
        queryFn: () => getValueAddedTaxById(id),
        queryKey: [VALUE_ADDED_TAXES_KEY, id],
        enabled: !!id,
        refetchOnWindowFocus: options?.refetchOnWindowFocus ?? true
    })
}