import { useEffect, useState } from "react"

import dayjs from "dayjs"

import { FieldData } from "@/components/FilterWrapper"

import { useAuthorizedOrganizationQuery } from "@/hook/AuthorizedOrganizations/useAuthorizedOrganizationQuery"
import { useFilterStorage } from "@/hook/useFilterStorage"
import { useClientQuery } from "@/hook/Vocabularies/useClientQuery"
import { useUserQuery } from "@/hook/Vocabularies/useUserQuery"

import { CONTRACTS_KEY } from "@/types/IContract"
import { FilterField, FilterFieldOperator, FilterFieldType, IFilterField } from "@/types/ui"

const contractsFilters: IFilterField[] = [
    {
        text: "Номер записи",
        name: "Number",
        type: FilterFieldType.Text,
        operator: FilterFieldOperator.Contains
    },
    {
        text: "Номер договора",
        name: "Name",
        type: FilterFieldType.Text,
        operator: FilterFieldOperator.Contains
    },
    {
        text: "Дата договора",
        name: "Date",
        type: FilterFieldType.DateRange
    },
    {
        text: "ИНН клиента",
        name: "ClientInn",
        type: FilterFieldType.Text,
        operator: FilterFieldOperator.Contains
    }
]

export const useContractsFilters = () => {
    const [filters, setFilters] = useState<FilterField[]>([])

    const { getFilter } = useFilterStorage({
        key: CONTRACTS_KEY
    })
    const { data: clients } = useClientQuery({
        OrderBy: "name asc"
    })
    const { data: users } = useUserQuery({
        OrderBy: "lastName asc"
      })
    const { data: organizations } = useAuthorizedOrganizationQuery({
        OrderBy: "name asc"
      })    
    const oldFilters: FieldData[] = getFilter()

    useEffect(() => {
        setFilters([...contractsFilters,
            {
                text: "Клиент",
                name: "ClientName",
                type: FilterFieldType.Select,
                values: clients?.response.data.map(m => ( { id: m.name, name: `${m.name}, ${m.inn}` } ))
            },
            {
                text: "Организация",
                name: "Organization",
                type: FilterFieldType.Select,
                values: organizations?.response.data.map(m => ( { id: m.name, name: `${m.name}, ${m.inn}` } ))
            },
            {
                text: "Автор",
                name: "AuthorId",
                type: FilterFieldType.Select,
                values: users?.response.data.map(m => ( { id: m.id, name: `${m.lastName} ${m.firstName}` } ))
            }
        ])
    }, [users, organizations, clients])

    return {
        filters: filters.map(f => {
            const currentFilter = oldFilters.find(i => (i.name as string[])[0] === f.name)

            if (!currentFilter || !currentFilter.value) {
                return f
            }

            switch (f.type) {
                case FilterFieldType.Date:
                  return {
                    ...f, value: dayjs(new Date(currentFilter.value as string))
                  }
                case FilterFieldType.DateRange:
                  return {
                    ...f, value: [
                      dayjs(new Date(currentFilter.value[0] as string)),
                      dayjs(new Date(currentFilter.value[1] as string))
                    ]
                  }
                default:
                  return {
                    ...f, value: currentFilter.value
                  }
              }
        })
    }
}