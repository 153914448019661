import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from "react"

import { Button, Drawer, Form, InputNumber, Spin } from "antd"
import dayjs from "dayjs"

import { DatePicker } from "@/components/DatePicker/DatePicker"

import { useValueAddedTaxMutation } from "@/hook/AuthorizedOrganizations/useValueAddedTaxMutation"
import { useValueAddedTaxByIdQuery } from "@/hook/AuthorizedOrganizations/useValueAddedTaxQuery"


export interface TaxAddFormProps {
    open(authorizedOrganizationId: number, id?: number): void;
    close(): void;
}

interface TaxAddFormValues {
    taxRate: number
    startDate: Date
}

export const ValueAddedTaxForm = forwardRef<TaxAddFormProps, {}>((props, ref) => {
    const [form] = Form.useForm<TaxAddFormValues>()
    const [id, setId] = useState<number>(0)
    const [organizationId, setOrganizationId] = useState<number>(0)
    const [isOpen, setIsOpen] = useState(false)
    const [isProcessing, setIsProcessing] = useState(false)

    const { 
        data: tax,
        isLoading: isTaxLoading,
        isFetching: isTaxFetching 
    } = useValueAddedTaxByIdQuery(id)

    const title = useMemo(() => {
        const tax = " значение НДС"
        if (id) {
          return "Редактировать" + tax
        }
    
        return "Добавить" + tax
    }, [id])

    const inputPercentProps = {
        min: 0, precision: 0, addonAfter: "%", style: { width: "100%" }
    }

    useImperativeHandle(ref, () => ({
        open(authorizedOrganizationId: number, id?: number) {
            onOpenHandler(authorizedOrganizationId, id)
        },
        close() {
          onCloseHandler()
        }
    }))

    useEffect(() => {
        if (tax && isOpen) {
            const response = tax.response

            form.setFields([
                { name: "taxRate", value: response.taxRate },
                { name: "startDate", value: response.startDate && dayjs(response.startDate) }
            ])
        }
    }, [id, tax, form, isOpen])

    const onOpenHandler = (authorizedOrganizationId: number, id?: number) => {
        setOrganizationId(authorizedOrganizationId)
        if (id) {
            setId(id)
        }
        form.resetFields()
        setIsOpen(true)
    }

    const { createMutation, updateMutation } = useValueAddedTaxMutation()

    const onFinish = async (data: TaxAddFormValues) => {
        try {
            setIsProcessing(true)

            const request = {
                startDate: data.startDate,
                taxRate: data.taxRate,
                authorizedOrganizationId: organizationId
            }

            if (id) {
              await updateMutation.mutateAsync({
                ...request,
                id: id
              })
            } else {
              await createMutation.mutateAsync(request)
            }
            form.resetFields()
            onCloseHandler()
          } finally {
            setIsProcessing(false)
          }
    }

    const onCloseHandler = () => {
        setId(0)
        form.resetFields()
        setIsOpen(false)
    }

    return(
        <>
            <Drawer
                title={title}
                width={820}
                onClose={onCloseHandler}
                open={isOpen}
                bodyStyle={{ paddingBottom: 80 }}
                extra={
                    <Button type="primary" disabled={isProcessing} onClick={form.submit}>
                        Сохранить
                    </Button>
                }
            >
                <Spin spinning={isTaxLoading && isTaxFetching}>
                    <Form layout="vertical" form={form} onFinish={onFinish}>
                        <Form.Item 
                            label="Ставка НДС" 
                            name="taxRate" 
                            rules={[{ required: true, message: "Пожалуйста укажите ставку НДС" }]}
                        >
                            <InputNumber placeholder="Процент %" {...inputPercentProps} />
                        </Form.Item>
                        <Form.Item 
                            label="Дата начала действия" 
                            name="startDate" 
                            rules={[{ required: true, message: "Пожалуйста укажите дату начала действия" }]}
                        >
                            <DatePicker style={{ width: "100%" }} 
                                        placeholder="Дата начала действия"
                                        disabledDate={(current) => current.isBefore(new Date(Date.now() - 24 * 60 * 60 * 1000))}/>
                        </Form.Item>
                    </Form>
                </Spin>
            </Drawer>
        </>
    )
})