import { useMutation, useQueryClient } from "@tanstack/react-query"

import { createValueAddedTax, deleteValueAddedTaxes, updateValueAddedTax } from "@/http/valueAddedTax"

import { useMessage } from "@/hook/useMessage"

import { VALUE_ADDED_TAXES_KEY } from "@/types/ITax"

export const useValueAddedTaxMutation = () => {
  const client = useQueryClient()
  const { Success, Error, Process } = useMessage(VALUE_ADDED_TAXES_KEY)

  const onSuccess = () => {
    client.invalidateQueries([VALUE_ADDED_TAXES_KEY]).then()
    Success()
  }

  const onMutate = () => {
    Process()
  }

  const onError = (e: any) => {
    Error(e)
  }

  const createMutation = useMutation({
    mutationFn: createValueAddedTax,
    onSuccess: () => onSuccess(),
    onMutate: () => onMutate(),
    onError: (e) => onError(e)
  })

  const updateMutation = useMutation({
    mutationFn: updateValueAddedTax,
    onSuccess: () => onSuccess(),
    onMutate: () => onMutate(),
    onError: (e) => onError(e)
  })

  const deleteMutation = useMutation({
    mutationFn: deleteValueAddedTaxes,
    onSuccess: () => onSuccess(),
    onMutate: () => onMutate(),
    onError: (e) => onError(e)
  })

  return {
    createMutation,
    updateMutation,
    deleteMutation
  }
}