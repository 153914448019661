import { client } from "./client"

import { IPagingResponse, IRequestParams, IResponse } from "@/types/api"
import { IContract, IContractClose, IContractCreate, IContractDelete, IContractUpdate } from "@/types/IContract"
import { IDownloadDocument } from "@/types/IDownloadDocument"
import { ITableColumn } from "@/types/ui"

export const getContracts = (params: IRequestParams): Promise<IPagingResponse<IContract>> =>
    client.get("/Contracts", {
        params
    })

export const getContractById = (id: string): Promise<IResponse<IContract>> => client.get(`/Contracts/${id}`)

export const createContract = (body: IContractCreate): Promise<IResponse<string>> =>
    client.post("/Contracts", body)

export const updateContract = (body: IContractUpdate): Promise<IResponse<string>> =>
    client.put("/Contracts", body)

export const closeContract = (body: IContractClose): Promise<IResponse<string>> =>
    client.put(`/Contracts/Close/${body.id}`, body)

export const deleteContracts = (params: IContractDelete): Promise<IResponse<any>> =>
    client.delete("/Contracts", { data: params })

export const printContract = (id: string, year: number): Promise<IResponse<IDownloadDocument>> =>
    client.get(`/Contracts/Print/${id}/${year}`)

export const getContractsExcel = (params?: IRequestParams, columns?: ITableColumn<IContract>[]): Promise<IResponse<IDownloadDocument>> =>
    client.post("/Contracts/Excel", columns, {
        params
    })