import { client } from "@/http/client"

import { IPagingResponse, IRequestParams, IResponse } from "@/types/api"
import { ITax, ITaxCreate, ITaxUpdate } from "@/types/ITax"

export const getValueAddedTaxes = (): Promise<IResponse<ITax[]>> => client.get("/ValueAddedTaxes")

export const getValueAddedTaxesByOrganization = (id: number, params: IRequestParams): Promise<IPagingResponse<ITax>> =>
  client.get(`/AuthorizedOrganizations/${id}/ValueAddedTaxes`, {
    params
  })

export const getValueAddedTaxById = (id: number): Promise<IResponse<ITax>> => client.get(`/ValueAddedTaxes/${id}`)

export const createValueAddedTax = (body: ITaxCreate): Promise<IResponse<number>> => client.post("/ValueAddedTaxes", body)

export const updateValueAddedTax = (body: ITaxUpdate): Promise<IResponse<string>> => client.put("/ValueAddedTaxes", body)

export const deleteValueAddedTaxes = (ids: number[]): Promise<IResponse<boolean>> =>
  client.delete("/ValueAddedTaxes", {
    data: {
      ids
    }
  })